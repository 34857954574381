

















import Vue from "vue";
import { Component } from "vue-property-decorator";

import { Seminar } from "@/core/models";
import Block from "../components/Block.vue";
import { Getter } from "vuex-class";

@Component({ components: { Block } })
export default class Sidebar extends Vue {
  @Getter("seminars/selected") seminar?: Seminar;
  @Getter("seminars/blockIndex") blockIndex!: number;
  @Getter("seminars/selectableUpTo") selectableUpTo!: [number, number];

  canSelectUpTo(i: number) {
    if (!this.seminar) return;
    if (this.selectableUpTo[0] > i)
      return this.seminar.blocks[i].elements.length - 1;
    else if (this.selectableUpTo[0] < i) return -1;
    else return this.selectableUpTo[1];
  }
}
