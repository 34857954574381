
























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { SeminarBlock } from "@/core/models";
import { Action, Getter } from "vuex-class";

@Component
export default class Block extends Vue {
  @Prop({ default: () => undefined }) block?: SeminarBlock;
  @Prop({ default: () => 0 }) index!: number;
  @Prop({ default: () => 0 }) canSelectUpTo!: number;
  @Getter("seminars/blockIndex") blockIndex!: number;
  @Getter("seminars/elementIndex") elementIndex!: number;
  @Action("seminars/setindices") set!: (d: [number, number]) => void;

  isCurrentElement(block: number, el: number) {
    return this.blockIndex === block && this.elementIndex === el;
  }

  selectEl(i: number) {
    if (this.canSelectUpTo < i) return;
    this.set([this.index, i]);
  }
}
